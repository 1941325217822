import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {TextField,Typography,Button} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import classNames from 'classnames';


import Utils from '../api/api';
import theme from '../layout/theme';

import WebConfig from '../api/config';

var config = WebConfig.production;
if (window.location.hostname === 'localhost') {
  config = WebConfig.development;
}


const styles = {
  hide: {
    display: 'none'
  },
  error: {
    color: theme.palette.error[500]
  },
  container: {
    /* position: 'fixed', */
    zIndex: 10001,
    left:0,
    right:0,
    top:56,
    bottom:0,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
  },
  [theme.breakpoints.up('sm')]: {
    container: {
      top: 64,
    },
  },
  form:{
    width: 300,
    margin: '0 auto',
    padding:30,
    border: 'solid 1px #eee',
    boxShadow: '0 0 15px rgba(0,0,0,0.1)',
    background: '#fff'
  },
  textField: {
    width: '100%',
  },
  formRow: {
    justifyContent:'flex-end'
  },
  formRowCmd: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0'
  }
};

class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
      username_error: false,
      password_error: false,
      error: false,
      error_message: ''
    };
  }
  componentDidMount(){
    //console.log(this.context.router.route.match.params);
    var $this = this;
    var isLogedIn = this.props.isLoggedIn;
    var redirect = this.context.router.route.match.params.url;

    Utils.getListData('users', 1, config.itemPerPage, '0,0', null, 1, function(data){
      if(data.status==='success'&&data.results.length===0){
        Utils.getSingleData('groupname','Administrator',function(groups){
          // console.log(groups);
          if(groups.status==='success'&&groups.results.length===0){
            var defaultGroup = {
              name: 'Administrator'
            };
            Utils.group_add(defaultGroup,function(res){
              if(res.status==='success'){
                let defaultUser = {
                  title:'Admin',
                  username:'admin',
                  password:'123@456',
                  email:'truonghoangphuc84@gmail.com',
                  avatar:'',
                  groupid:res.insertedIds[0]
                };
                Utils._add('users',defaultUser,function(user){
                  console.log(user);
                });
              }
            });
          }else if(groups.results.length>0){
            let defaultUser = {
              title:'Admin',
              username:'admin',
              password:'123@456',
              email:'truonghoangphuc84@gmail.com',
              avatar:'',
              groupid:groups.results[0]._id
            };
            Utils._add('users',defaultUser,function(user){
              //console.log(user);
            });
          }
        });
      }
    });

    if(isLogedIn&&redirect===undefined){
      this.context.router.history.push('dashboard');
    }else if(isLogedIn&&redirect!==undefined){
      if(redirect.indexOf(':')===0){
        redirect=redirect.replace(':','/');
      }
      $this.context.router.history.push(redirect);
    }
    var main = document.querySelector('.main');
    if(main!==null) main.style.height = window.innerHeight - document.querySelector('header').offsetHeight;// - document.querySelector('footer').offsetHeight + 'px';
  }
  handleValidate(id,e){
    var obj = {};
    obj[id] = e.target.value;

    //console.log(e.target.value)
    //validate here
    var p = id+'_error';
    if(e.target.value.trim()===''||e.target.value===null){
      obj[p] = true;
    }else{
      obj[p] = false;
    }
    this.setState(obj,()=>{
      if(!this.state.username_error && !this.state.password_error){
        obj['error'] = false;
      }else{
        obj['error'] = true;
        obj['error_message'] = 'Vui lòng nhập tất cả các field (*)';
      }
      this.setState(obj);
    });
  }
  handleLogin(e){
    var $this = this,
        obj = $this.state;

    obj.username_error = $this.state.username === '' || $this.state.username === null;
    obj.password_error = $this.state.password === '' || $this.state.password === null;

    obj.error = obj.username_error || obj.password_error;
    obj.error_message = 'Vui lòng nhập tất cả các field (*)';
    this.setState(obj);

    if(obj.error){
      return;
    }

    var redirect = this.context.router.route.match.params.url;
    if(redirect!==undefined){
      if(redirect.indexOf(':')===0){
        redirect=redirect.replace(':','/');
      }
    }
    Utils.login(this.state.username,this.state.password,function(data){
      //console.log(data);
      if(data.message==='success'){
        localStorage.setItem('user_token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('userg',JSON.stringify({group:Utils.utoa(data.user.groupid)}));
        //console.log($this.context.router);
        // $this.props.updateStatus();
        // if(redirect!==undefined){
        //   $this.context.router.history.push(redirect);
        // }else{
        //   $this.context.router.history.push('/');
        // }
        $this.props.updateStatus(function(){
          //console.log(redirect);
          if(redirect!==undefined){
            $this.context.router.history.push(redirect);
          }else{
            $this.context.router.history.push('dashboard');
          }
        });
      }else{
        obj.error = true;
        obj.error_message = data.message;
        $this.setState(obj);
      }
    });
  }
  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}>
        <form className={classes.form} noValidate>
          <Typography type="body2" className={classNames(classes.error, !this.state.error && classes.hide)}>{this.state.error_message}</Typography>
          <TextField
            id="username"
            label="Username"
            className={classes.textField}
            value={this.state.username}
            onChange={(event) => this.handleValidate('username',event)}
            margin="normal"
            autoComplete="username"
            //InputClassName={classes.textInput}
            required={true}
            error={this.state.username_error}
          />
          <TextField
            id="password"
            label="Password"
            className={classes.textField}
            type="password"
            autoComplete="current-password"
            margin="normal"
            value={this.state.password}
            onChange={event => this.handleValidate('password',event)}
            //InputClassName={classes.textInput}
            required={true}
            error={this.state.password_error}
          />
          <FormGroup row className={classes.formRowCmd}>
            <a style={{marginBottom: 10}} href="/#/forgot-password" title="Quên mật khẩu">Quên mật khẩu</a>
            <Button variant="contained" color="primary" onClick={(event)=>this.handleLogin(event)}>Đăng nhập</Button>
          </FormGroup>
        </form>
      </div>
    );
  }
}

Login.contextTypes = {
  router: PropTypes.object
};


export default withStyles(styles)(Login);
